import React, { FC, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import googleSvg from "images/Google.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api/config";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "context/userContext";
import { verify } from "crypto";
import { useSearchParams } from "react-router-dom";
import { values } from "lodash";

export interface VerifyOtpProps {
  className?: "";
}

const VerifyOtp: FC<VerifyOtpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState(120); 
  const authContext = useContext(AuthContext);

  const queryParams = new URLSearchParams(window.location.search);
  let [searchParams] = useSearchParams();
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const phone = searchParams?.get("phoneNumber");
    if (phone) {
      setPhoneNumber(phone);
    }  }, [searchParams]);


    useEffect(() => {
      // Autofocus the first input on mount
      if(firstInputRef.current){
        firstInputRef.current.focus();
      }
    }, [searchParams]);

    useEffect(() => {
      // Countdown timer for 3 minutes
      if (timeLeft > 0) {
        const timerId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearInterval(timerId);
      }
    }, [timeLeft]);

    const formatTime = () => {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };


    const handleOtpChange = (value: any, index: any) => {
      if (!/^\d*$/.test(value)) return;

      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
    // Update Formik's otp value with the joined OTP digits
      validation.setFieldValue("otp", newOtp.join(""));
      // Focus the next input
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    };

  // Function to verify OTP
  const verifyOtpNumber = async (values: {
    otp: number | undefined;
  }) => {
    // if (values.otp.length() !== 6) {
    //   setError("Please enter a 6-digit OTP.");
    //   return;
    // }

    setisLoading(true);
    try {
      console.log("ppp", phoneNumber);
      
      const response = await axios.post(`${API_URL}/users/verify-otp`, {
        // otp: Number(values.otp),
        otp: Number(otp.join("")),
        phoneNumber: phoneNumber,
      });
      // Check for a successful response and handle the token
      if (response.status === 200 && !response.data.error) {
        localStorage.setItem("token", response.data.token); // Store token for further authentication
        toast.success(response.data.message || "Login successful!");
        authContext.getAdminData();
        navigate("/"); // Redirect to the homepage or a protected route
      } else {
        navigate(`/signup?phoneNumber=${phoneNumber}`)
      }
    } catch (err) {
      setError("An error occurred during verification.");
    } finally {
      setisLoading(false);
    }
  };

  const resendOTP = async() => {
    
    setOtp(Array(6).fill(""));
    setTimeLeft(120);
    if (!phoneNumber) {
      setError("Phone number is missing. Please try again.");
      return;
    }
    validation.setFieldValue("otp", "");
    try {
      const response = await axios.post(`${API_URL}/users/resend-otp`, {
        phoneNumber: phoneNumber,
      });
      if (response.data.error === false) {
        toast.success(response.data.message || "OTP resent successfully!");
        if (firstInputRef.current) {
          firstInputRef.current.focus(); // Focus the first input box
        }
      } else {
        setError(response.data.message || "Failed to resend OTP.");
      }
    } catch (error) {
      setError("An error occurred while resending OTP.");
    }
  }

  // otp input validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      phoneNumber: "",
      otp:undefined,
    },

    validationSchema: Yup.object({
      otp: Yup.number()
      .typeError("OTP must be a number")
      .min(100000, "OTP must be a 6-digit number")
      .max(999999, "OTP must be a 6-digit number")
      .required("OTP is required"),
      phoneNumber: Yup.string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
    }),
    onSubmit: verifyOtpNumber,
  });

  return (
    <>
      <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
        <Helmet>
          <title>Ezstays / Login</title>
        </Helmet>

        <div className="container my-20 lg:mb-8 relative ">
          <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Verify OTP
          </h2>
          <div className="max-w-md mx-auto space-y-10">
            {/* FORM */}
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={validation.handleSubmit}
              method="post"
            >
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Enter 6 digit OTP
                </span>
                {/* <Input
                  name="otp"
                  type="text"
                  className="mt-1 text-neutral-600"
                  value={validation.values.otp}
                  onChange={validation.handleChange}
                  maxLength={6}
                /> */}
                   <div className="flex space-x-2 justify-center mt-2">
                {otp.map((digit, index) => (
                  <Input
                  name="otp"
                    key={index}
                    id={`otp-input-${index}`}
                    type="tel"
                    ref={index === 0 ? firstInputRef : undefined} 
                    className="w-10 h-14 text-center text-neutral-600 rounded-lg"
                    value={digit}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    maxLength={1}
                  />
                ))}
              </div>
                {validation.touched.otp && validation.errors.otp ? (
                  <span className="text-red-500 text-sm">
                    {validation.errors.otp}
                  </span>
                ) : null}
              </label>

              <ButtonPrimary disabled={isLoading} type="submit" className="blue-btn">
                Verify
              </ButtonPrimary>
            </form>
            {/* ==== */}
            {/* <div className="grid gap-3 text-sm">
              <span className="block text-start text-neutral-700 dark:text-neutral-300">
                <Link to="" onClick={resendOTP}>Resend OTP</Link>
              </span>
            </div> */}
              <div className="text-center text-sm text-neutral-700 dark:text-neutral-300">
            {timeLeft > 0 ? (
              <span>Resend OTP in {formatTime()}</span>
            ) : (
              <Link to="" onClick={resendOTP}>Resend OTP</Link>
            )}
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
