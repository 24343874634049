import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Button from "@mui/material/Button";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon, HeartIcon, EyeIcon } from "@heroicons/react/24/outline";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../api/config";
import axios from "axios";
import DetailPagetLayout from "../Layout";
import { AuthContext } from "context/userContext";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionDateRange from "components/DatePickerComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAirbnb } from "@fortawesome/free-brands-svg-icons";
import { StarIcon } from "@heroicons/react/24/solid";
import GallerySkeleton from "./GallerySkeleton";
import PropertyInfoSkeleton from "./PropertyInfoSkeleton";
import NewHeader from "./NewHeader";

const StayDetailPageContainer: FC<{}> = () => {
  const [configData, setConfigData] = useState<any>({});
  const [show, setShow] = useState<any>(false);
  const [propertyData, setPropertyData] = useState<any>({});
  const [totalOwnerProperty, setTotalOwnerProperty] = useState<number>(0);

  const authContext = useContext(AuthContext);
  const {
    showSearchModal,
    setShowSearchModal,
    getPropertyData,
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    endDate,
    handleDateChange,
    showHeight,
    guests: guestSearch,
    setGuests,
    userData,
    allReview,
    setAllReview,
    reviewValue,
    setReviewValue,
    ratingValue,
    setRatingValue,
    setAvgRating,
  } = authContext;

  const queryParams = new URLSearchParams(window.location.search);
  const propIdParam = queryParams.get("propID");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [errorEmptyReview, setErrorEmptyReview] = useState("");
  const [showHeader, setShowHeader] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  //rating allReviews
  const getPropertyRatings = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/users/get-prop-review?propId=${propIdParam}`
      );
      if (response.data.error === false) {
        setAllReview(response.data?.allReviews);
        calculateAverageRating(response.data?.allReviews);
      }
    } catch (err) {
      console.error("error while fetching userInfo", err);
      toast.error(`${err}`);
    }
  };
  const calculateAverageRating = (allReview: any) => {
    if (allReview.length > 0) {
      const allRating = allReview.reduce((total: number, review: any) => {
        return total + review.rating;
      }, 0);
      const average = allRating / allReview.length;
      setAvgRating(Number(average.toFixed(1)));
    } else {
      setAvgRating(0);
    }
  };

  const getOnePropertyDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/property/get-propertyCard-details?id=${propIdParam}&userId=${userData._id}`
      );
      if (response.data.error === false) {
        if (response.data.getTransaction) {
          setShow(true);
        }
        setPropertyData(response.data.propertyDetails);

        setTotalOwnerProperty(response.data.countTotalProperties);
        getPropertyRatings();
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error while fetching property details");
      console.error("Error fetching details", err);
    } finally {
      setLoading(false);
    }
  };

  //show phone number
  const getPhoneNumber = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return toast.error("You need login to view phone number..");
    }
    const queryParams = new URLSearchParams(window.location.search);
    const propIdParam = queryParams.get("propID");
    try {
      const response = await axios.post(
        `${API_URL}/users/showphone?propId=${propIdParam}`,
        {},
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === true) {
        setShow(false);
        return toast.error(`${response.data.message}`);
      }

      setShow(true);
      toast.success("Phone number fetch successfully");
    } catch (err) {
      toast.error(`${err}`);
      setShow(false);
    }
    authContext.getAdminData();
  };

  // send email
  const sendEmail = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return toast.error("You need to login to get a callback");
    }
    const queryParams = new URLSearchParams(window.location.search);
    const propIdParam = queryParams.get("propID");
    try {
      const response = await axios.post(
        `${API_URL}/users/getCallBack?propId=${propIdParam}`,
        {},
        { headers: { token: token } }
      );
      if (response.data.error === true) {
        return toast.error(`${response.data.message}`);
      }
      toast.success("Email sent successfully");
    } catch (err) {
      toast.error(`${err}`);
    }
    authContext.getAdminData();
  };

  //get coins from config
  const getConfigCoins = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/users/getConfigCoin`,
        {},
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === true) {
        return toast.error(`${response.data.message}`);
      }

      setConfigData(response.data.configDB);
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const handleChangeReview = (e: any) => {
    if (token) {
      setReviewValue(e.target.value);
      setErrorEmptyReview("");
    } else {
      return setErrorEmptyReview(
        "Please log in to leave a review and share your experience with this property"
      );
    }
  };

  const handleSubmitReview = async () => {
    const token = localStorage.getItem("token");
    if (reviewValue === "") {
      setErrorEmptyReview("Please enter your thoughts.");
      return;
    } else {
      setErrorEmptyReview("");
    }
    try {
      const response = await axios.post(
        `${API_URL}/users/add-review`,
        {
          propId: propIdParam,
          review: reviewValue,
          rating: ratingValue,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === false) {
        toast.success(<>review posted!</>);

        getPropertyRatings();
        setReviewValue("");
        setRatingValue(5);
        getPropertyData();
      }
    } catch (err) {
      console.error("error while fetching userInfo", err);
      toast.error(`${err}`);
    }
  };

  useEffect(() => {
    getOnePropertyDetails();
  }, [userData]);

  const handleConfirmPhoneNumber = () => {
    getPhoneNumber();
    setConfirmDialogVisible(false);
  };

  const handleCancelPhoneNumber = () => {
    setConfirmDialogVisible(false);
  };

  const {
    type,
    subtype,
    title,
    country,
    area_name,
    city,
    guests,
    bedrooms,
    beds,
    bathrooms,
    pet,
    party_organizing,
    cooking,
    smoking,
    drinking,
    additional_rules,
    place_descriptions,
    cover_image,
    galleryImgs,
    count,
    avgRating,
    price,
  } = propertyData;

  const PHOTOS: string[] = [];
  if (cover_image) {
    PHOTOS.push(cover_image);
  }
  if (galleryImgs) {
    PHOTOS.push(...galleryImgs);
  }

  const Amenities_demos = [
    { name: "Washing machine", icon: "la-utensils" },
    { name: "Makeup table", icon: "la-hot-tub" },
    { name: "Detergent", icon: "la-spa" },
    { name: "Cloth hook", icon: "la-tshirt" },
    { name: "Fridge", icon: "la-ice-cream" },
    { name: "Free toiletries", icon: "la-bath" },
    { name: "Wifi", icon: "la-wifi" },
    { name: "Internet", icon: "la-globe" },
    { name: "TV", icon: "la-tv" },
    { name: "Air conditioning", icon: "la-thermometer-half" },
    { name: "Fan", icon: "la-fan" },
    { name: "Private entrance", icon: "la-door-closed" },
    { name: "Heater", icon: "la-fire" },
    { name: "Clothes dryer", icon: "la-tshirt" },
    { name: "Baby cot", icon: "la-baby" },
    { name: "Desk", icon: "la-desktop" },
    { name: "Dryer", icon: "la-wind" },
    { name: "Wardrobe", icon: "la-tshirt" },
    { name: "Extra cushion", icon: "la-couch" },
    { name: "Gas stove", icon: "la-fire-alt" },
    { name: "Toilet paper", icon: "la-toilet-paper" },
    { name: "Hot pot", icon: "la-coffee" },
    { name: "Bathroom heaters", icon: "la-shower" },
    { name: "Kettle", icon: "la-coffee" },
    { name: "Dishwasher", icon: "la-dumpster" },
    { name: "BBQ grill", icon: "la-fire" },
    { name: "Toaster", icon: "la-bread-slice" },
    { name: "Towel", icon: "la-bath" },
    { name: "Dining table", icon: "la-chair" },
    { name: "Fire siren", icon: "la-bell" },
    { name: "Fire extinguisher", icon: "la-fire-extinguisher" },
    { name: "Anti-theft key", icon: "la-key" },
    { name: "Safe vault", icon: "la-lock" },
  ];

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = (e: any) => {
    router(`${thisPathname}?modal=PHOTO_TOUR_SCROLLABLE&propID=${propIdParam}`);
    e.stopPropagation();
  };
  const onClickShowPhoneNumber = () => {
    if (show) {
      return;
    }
    if (!token) {
      return toast.error("You need login to view phone number..");
    }
    getConfigCoins();

    setConfirmDialogVisible(true);
  };

  let [isOpenModalReviews, setIsOpenModalReviews] = useState(false);
  function closeModalReviews() {
    setIsOpenModalReviews(false);
  }

  function openModalReviews() {
    sortReviews("recent");
    setIsOpenModalReviews(true);
  }
  const [sortedReviews, setSortedReviews] = useState<any[]>([]);
  const [sortOption, setSortOption] = useState("recent");

  const handleSortChange = (event: any) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);

    sortReviews(selectedOption);
  };

  const sortReviews = (sortOption: string) => {
    let sorted = [...allReview];

    if (sortOption === "recent") {
      sorted.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (sortOption === "highest") {
      sorted.sort((a, b) => +a.rating - +b.rating);
    } else if (sortOption === "lowest") {
      sorted.sort((a, b) => +b.rating - +a.rating);
    }

    setSortedReviews(sorted);
  };

  const address1 = `${area_name}, ${city}, ${country}`;

  //SEARCCH BAR DETAIL PAGE
  const renderSearchBar = () => {
    return (
      <div
        style={{
          backgroundColor: showSearchModal
            ? "rgba(0, 0, 0, 0.5)"
            : "transparent",
          opacity: showSearchModal ? 0.9 : 1,
          position: "fixed",
          top: 90,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 50,
          display: showSearchModal ? "block" : "none",
        }}
      >
        <div className={`HeroSearchForm2Mobile`}>
          <Transition appear show={showSearchModal} as={Fragment}>
            <Dialog
              as="div"
              className="HeroSearchFormMobile__Dialog relative z-max"
              onClose={() => setShowSearchModal(false)}
            >
              <div
                className="fixed inset-0 bg-dark-100 dark:bg-neutral-900 "
                onClick={() => setShowSearchModal(false)}
              >
                <div className="flex">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out transition-transform"
                    enterFrom="opacity-0 scale-75"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in transition-transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                  >
                    <Dialog.Panel
                      className="relative h-20vh flex-1 flex flex-col justify-between"
                      style={{
                        minHeight: showHeight ? "75vh" : undefined,
                        backgroundColor: "transparent",
                      }}
                    >
                      {showSearchModal && (
                        <Tab.Group manual>
                          <div
                            className="flex-1 mx-4
                           sm:px-4 flex"
                            style={{ marginLeft: "0px" }}
                          >
                            <Tab.Panels
                              className="flex-1 overflow-y-auto hiddenScrollbar pt-4 container"
                              style={{ paddingTop: "7rem" }}
                              onClick={(e) => {
                                setShowSearchModal(false);
                                e.stopPropagation();
                              }}
                            >
                              <Tab.Panel>
                                <div className="transition-opacity animate-[myblur_0.4s_ease]">
                                  {showSearchModal && (
                                    <SectionHeroArchivePage
                                      getPropertyFunc={getPropertyData}
                                      searchLocationValue={searchLocationValue}
                                      setSearchLocationValue={
                                        setSearchLocationValue
                                      }
                                      startDate={startDate}
                                      endDate={endDate}
                                      handleDateChange={handleDateChange}
                                      guests={guestSearch}
                                      setGuests={setGuests}
                                      currentPage="Stays"
                                      currentTab="Stays"
                                      className="lg:pb-1"
                                      setShowSearchModal={setShowSearchModal}
                                    />
                                  )}
                                </div>
                              </Tab.Panel>
                            </Tab.Panels>
                          </div>
                        </Tab.Group>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    );
  };

  const renderPropertyInfo = () => {
    return (
      <>
        {loading ? (
          <PropertyInfoSkeleton />
        ) : (
          <div className="listingSection__wrap !space-y-6">
            <div className="flex justify-between items-center">
              <Badge name={`${type} - ${subtype ? subtype : ""}`} />
              <LikeSaveBtns propID={propIdParam} />
              {/* {subtype} */}
            </div>

            {propertyData && (
              <h2 className="text-2xl sm:text-3xl lg:text-4xl lg:font-semibold font-[500]">
                {title}
              </h2>
            )}

            <div className="flex items-center space-x-4 ">
              {!!count && <StartRating reviewCount={count} point={avgRating} />}

              <span className="flex items-baseline justify-center ">
                <i className="las la-map-marker-alt"></i>
                {propertyData && <span className="ml-1 text-xs lg:text-[15px]"> {address1}</span>}
              </span>
            </div>

            <div className="flex items-center">
              <Avatar
                sizeClass="lg:h-10 lg:w-10 h-8 w-8"
                radius="rounded-full"
                userName={propertyData?.ownerID?.name || ""}
                imgUrl={propertyData?.ownerID?.image || ""}
              />
              <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 text-xs lg:text-[14px]">
                Hosted by{" "}
                <span className="text-neutral-900 dark:text-neutral-200 font-medium text-xs lg:text-[14px]">
                  {propertyData?.ownerID?.name || "Not found"}
                </span>
              </span>
            </div>
            {/* AIR BNB LINK
            {propertyData.airbnb_link ? (
              <>
                <div className="inline">
                  <a
                    href={propertyData?.airbnb_link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ backgroundColor: "#dbeafe" }}
                    className="inline-flex items-center px-3 py-1 text-xs font-medium text-blue-800 hover:text-blue-950 focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full"
                  >
                    <FontAwesomeIcon
                      icon={faAirbnb as IconProp}
                      className="text-sm text-red-800	mr-2"
                    />
                    View on Airbnb
                  </a>
                </div>
              </>
            ) : (
              ""
            )} */}

                 {/* Visitors Section */}
          <div className="flex items-center space-x-6 text-xs lg:text-sm text-neutral-700 dark:text-neutral-300">
            <div className="flex items-center space-x-2">
            <HeartIcon className="lg:w-5 lg:h-5 w-3 h-3" />
            <span>{propertyData?.likesCount || 0} likes</span>
            </div>
            <div className="flex items-center space-x-2">
            <EyeIcon className="lg:w-5 lg:h-5 w-3 h-3" />
            <span>{propertyData?.viewsCount || 0} views</span>
            </div>
          </div>

            <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

            <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 lg:text-sm text-xs text-neutral-700 dark:text-neutral-300">
              <div className="flex items-center lg:space-x-3 space-x-2 ">
                <i className=" las la-user text-2xl "></i>
                <span className="">
                  {guests}{" "}
                  <span className="hidden sm:inline-block">guests</span>
                </span>
              </div>
              <div className="flex items-center lg:space-x-3 space-x-2">
                <i className=" las la-bed text-2xl"></i>
                <span className=" ">
                  {beds} <span className="hidden sm:inline-block">beds</span>
                </span>
              </div>
              <div className="flex items-center lg:space-x-3 space-x-2">
                <i className=" las la-bath text-2xl"></i>
                <span className=" ">
                  {bathrooms}{" "}
                  <span className="hidden sm:inline-block">baths</span>
                </span>
              </div>
              <div className="flex items-center lg:space-x-3 space-x-2">
                <i className=" las la-door-open text-2xl"></i>
                <span className=" ">
                  {bedrooms}{" "}
                  <span className="hidden sm:inline-block">bedrooms</span>
                </span>
              </div>
            </div>
              {/* Visitors Section */}
          {/* <div className="flex items-center space-x-6 text-xs lg:text-sm text-neutral-700 dark:text-neutral-300">
            <div className="flex items-center space-x-2">
            <HeartIcon className="lg:w-5 lg:h-5 w-3 h-3" />
            <span>{propertyData?.likesCount || 0} likes</span>
            </div>
            <div className="flex items-center space-x-2">
            <EyeIcon className="lg:w-5 lg:h-5 w-3 h-3" />
            <span>{propertyData?.viewsCount || 0} views</span>
            </div>
          </div> */}
          </div>
        )}
      </>
    );
  };

  const renderStayInfo = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl lg:font-semibold font-[500]">Stay Information</h2>
        <span className="text-neutral-6000 dark:text-neutral-300 text-sm lg:text-[16px]">
          {place_descriptions}
        </span>
      </div>
    );
  };

  const renderAmenities = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl lg:font-semibold font-[500]">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-sm lg:text-[16px]">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more amenities
          </ButtonSecondary>
        </div>
        {renderModalAmenities()}
      </div>
    );
  };

  const renderModalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[99999] flex items-center justify-center bg-black bg-opacity-40"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center w-full">
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Amenities
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModalAmenities} />
                  </span>
                </div>
                <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderAvailability = () => {
    return (
      <>
        <div className="flex items-center space-x-4 mb-2">
          <SectionDateRange price={price}/>
        </div>

        {/* <div
          className={`${
            isSticky ? "flex mt-2 " : "listingSectionSidebar__wrap shadow-xl lg:pb-2 pb-3"
          }`}
        > */}
         <div
          className={`${
            isSticky ? "flex mt-2 " : "p-4 shadow-xl "
          }`}
        >
          {/* PRICE */}
          {/* <div className="flex justify-between">
            <span className="text-3xl font-semibold self-center">
              <div
                className={`${
                  isSticky
                    ? "p-0 flex justify-between items-center space-x-2 rounded-lg text-base"
                    : "2xl:p-4 lg:p-1 p-1 flex justify-between items-center space-x-2 rounded-lg"
                }`}
              >
                <span
                  className="lg:font-semibold lg:text-3xl font-[500] text-xl"
                  style={{ fontFamily: "Roboto" }}
                >
                  {Number(price).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  })}
                </span>
                <span className="lg:text-[16px] text-xs text-neutral-500 font-normal">
                  /night
                </span>
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"></span>
              </div>
            </span>
            {!!count && <StartRating reviewCount={count} point={avgRating} />}
          </div> */}

          {/* <PopoverPopupState
            show={show}
            onClickShowPhoneNumber={() => onClickShowPhoneNumber()}
          /> */}
          <div className="lg:flex md:flex-row flex flex-col md:gap-x-3 gap-y-3 2xl:!mt-1 lg:!mt-1">
            <ButtonPrimary
              // className="md:w-1/2 bg-primary-6000 text-white hover:bg-primary-700 lg:py-3"
              className="md:w-1/2 text-white blue-btn lg:py-3"

              onClick={onClickShowPhoneNumber}
            >
              Call immediately
            </ButtonPrimary>
            <ButtonPrimary
              // className="md:w-1/2 bg-primary-6000 text-white hover:bg-primary-700"
              className="md:w-1/2  text-white blue-btn"

              // onClick={sendEmail}
            >
              Get Callback
            </ButtonPrimary>
          </div>
        </div>
      </>
    );
  };

  // const renderSection5 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Host Information</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* host */}
  //       <div className="flex items-center space-x-4">
  //         <Avatar

  //           hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
  //           sizeClass="h-14 w-14"
  //           radius="rounded-full"
  //           userName={propertyData?.ownerID?.name || ""}
  //           imgUrl={propertyData?.ownerID?.image || ""}
  //         />
  //         <div>
  //           <a className="block text-xl font-medium" href="##">
  //             {propertyData?.ownerID?.name || "Not found"}
  //           </a>
  //           {/* <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
  //             <StartRating />
  //           </div> */}
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className="block text-neutral-6000 dark:text-neutral-300">
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //             />
  //           </svg>
  //           <span>
  //             Joined in{" "}
  //             {moment(propertyData?.ownerID?.createdAt).format("MMMM YYYY")}
  //           </span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <MapPinIcon className="w-5 h-5 lg:w-6 lg:h-6" />

  //           <span>{totalOwnerProperty} places</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderReviews = () => {
    return (
      <>
        {/* <div className="nc-ListingStayDetailPage py-8"> */}

        <div className="lg:p-8 p-1 ">
          {allReview.length > 0 ? (
            <header className="text-2xl lg:font-semibold font-[500] text-left ">
              Reviews ({allReview.length})
            </header>
          ) : (
            <header>
              <h2 className="text-2xl font-semibold">
                Reviews(No reviews yet)
              </h2>
              <p className="text-sm text-neutral-6000 mt-1">
                Be the first to leave a review for this property and help others
                make informed decisions!
              </p>
            </header>
          )}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 m-auto lg:py-5 py-3"></div>
          {token ? (
            <>
              <div className="lg:space-y-4 space-y-2">
                <FiveStartIconForRate
                  iconClass="lg:w-6 lg:h-6 w-4 h-4"
                  className="space-x-0.5 cursor-pointer"
                  ratingValue={ratingValue}
                  setRatingValue={setRatingValue}
                />
                <div className="relative lg:w-1/2 w-full">
                  <Input
                    fontClass=""
                    sizeClass="lg:h-16 h-10 lg:text-sm text-xs px-4 py-3"
                    rounded="rounded-3xl"
                    value={reviewValue}
                    onChange={handleChangeReview}
                    placeholder="Share your thoughts ..."
                  />

                  <ButtonCircle
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 blue-btn"
                    size=" lg:w-12 lg:h-12 w-7 h-7"
                    onClick={handleSubmitReview}
                  >
                    <ArrowRightIcon className="lg:w-5 lg:h-5 w-3 h-3" />
                  </ButtonCircle>
                </div>
                {errorEmptyReview && (
                  <p className="text-red-500 text-sm">{errorEmptyReview}</p>
                )}
              </div>
            </>
          ) : (
            <>
              <a
                href={`/login?propID=${propIdParam}`}
                // className=" w-fit cursor-pointer ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 lg rounded-full px-6 py-3"
                className=" w-fit cursor-pointer ttnc-ButtonPrimary disabled:bg-opacity-70 blue-btn text-neutral-50 lg rounded-full px-6 py-3"

              >
                Leave a review
              </a>
            </>
          )}

          {/* REVIEWS CONTENT */}
          {allReview ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-24 gap-y-16 pt-8">
              {allReview
                .filter((_, i) => i < 4)
                .slice()
                .reverse()
                .map((review: any, index: number) => {
                  return (
                    <div className="text-left" key={index}>
                      <CommentListing key={index} className="" data={review} />
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}

          <div className="mt-10">
            {allReview.length > 4 ? (
              <>
                <ButtonSecondary onClick={openModalReviews}>
                  View more reviews
                </ButtonSecondary>
                {renderModalReviews()}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };
  const renderModalReviews = () => {
    return (
      <Transition appear show={isOpenModalReviews} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-40"
          onClose={closeModalReviews}
        >
          <div className="min-h-screen px-4 text-center w-full">
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className=" relative lg:px-5 lg:py-3 px-3 py-2 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <span className="">
                    <ButtonClose onClick={closeModalReviews} className="" />
                  </span>

                  <div className="flex justify-between lg:py-2 lg:pt-5 pt-3">
                    <h1
                      className="self-center text-left text-2xl font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      <span className="flex items-end lg:text-sm text-xs lg:text-[20px]">
                        <StarIcon
                          className={`lg:w-7 lg:h-7 w-4 h-4 inline-flex items-baseline text-yellow-500`}
                        />

                        <span className="lg:text-lg">All Reviews</span>
                      </span>
                    </h1>
                    <select
                      className="w-40 !cursor-pointer border border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-800 full-rounded-md py-2 px-3 text-sm"
                      onChange={handleSortChange}
                      value={sortOption}
                    >
                      <option
                        value="recent"
                        className="!cursor-pointer"
                      >
                        <div className="cursor-pointer text-xs">Recently Added</div>
                      </option>
                      <option
                        value="highest"
                        className="!cursor-pointer"
                      >
                        Highest Rated
                      </option>
                      <option
                        value="lowest"
                        className="!cursor-pointer"
                      >
                        Lowest Rated
                      </option>
                    </select>
                  </div>
                </div>
                <div className="lg:px-8 lg:py-3 px-4 py-2 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  {sortedReviews
                    .filter((_, i) => i < 1212)
                    .slice()
                    .reverse()
                    .map((review: any, index: number) => {
                      return (
                        <div className="text-left	">
                          <CommentListing
                            key={index}
                            className="lg:py-3 lg:my-3 py-2 my-2"
                            data={review}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderPropertyRules = () => {
    const arrayofRules = [
      { name: "Pet", rule: pet },
      { name: "Party Organizing", rule: party_organizing },
      { name: "Cooking", rule: cooking },
      { name: "Smoking", rule: smoking },
      { name: "Drinking", rule: drinking },
    ];
    const convertToAllowedString = (allowed: Boolean) =>
      allowed ? "Allowed" : "Not allowed";
    const convertedRules = arrayofRules.map((rule: any) => ({
      ...rule,
      time: convertToAllowedString(rule),
    }));
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl lg:font-semibold font-[500]">Rules Of Property</h2>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        <div>
          <h4 className="text-lg lg:font-semibold font-[500]">House Rules</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            {arrayofRules.map((item, index) => (
              <div
                key={index}
                className="flex space-x-10 justify-between py-3 rounded-lg"
              >
                <span className="flex gap-4">
                  {item.rule ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* Circle */}
                      <circle cx="50" cy="50" r="45" fill="#4CAF50" />

                      {/* Tick */}
                      <path
                        d="M30 45 L45 60 L75 30"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* Circle */}
                      <circle cx="50" cy="50" r="45" fill="#FF0000" />

                      {/* Cross */}
                      <line
                        x1="30"
                        y1="30"
                        x2="70"
                        y2="70"
                        stroke="#FFFFFF"
                        strokeWidth="6"
                        strokeLinecap="round"
                      />
                      <line
                        x1="70"
                        y1="30"
                        x2="30"
                        y2="70"
                        stroke="#FFFFFF"
                        strokeWidth="6"
                        strokeLinecap="round"
                      />
                    </svg>
                  )}
                  {item.name}
                </span>
              </div>
            ))}
          </div>
        </div>
        {/* CONTENT */}
        <div>
          <div className="prose sm:prose">
            {additional_rules && additional_rules.length > 0 ? (
              <>
                {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}
                <h4 className="text-lg lg:font-semibold font-[500]">Additional Rules</h4>
                <ul className="mt-3 space-y-2">
                  {additional_rules.map((rule: string, index: number) => (
                    <li className="text-sm lg:text-[16px]" key={index}>{rule}</li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  interface ConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
  }

  const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onConfirm,
    onCancel,
  }) => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {open && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
              onClick={onCancel}
            ></div>
            <div
              style={{
                backgroundColor: "white",
                zIndex: 2000,
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                Call immediately?
              </div>
              <div style={{ marginTop: "10px" }}>
                <p>
                  Are you sure you want to view phone number with{" "}
                  {configData.coin} coins?
                </p>
              </div>
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <Button onClick={onCancel}>No</Button>
                <span style={{ marginLeft: "10px" }}></span>
                <ButtonPrimary onClick={onConfirm}>Yes</ButtonPrimary>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  // const renderSidebar = () => {
  //   return (
  //     <div
  //       className={`${
  //         isSticky ? "flex mt-2 " : "listingSectionSidebar__wrap shadow-xl"
  //       }`}
  //     >
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold self-center">
  //           <div
  //             className={`${
  //               isSticky
  //                 ? "p-0 flex justify-between items-center space-x-4 rounded-lg text-base"
  //                 : "p-4 flex justify-between items-center space-x-4 rounded-lg"
  //             }`}
  //           >
  //             <span
  //               className="font-semibold text-3xl"
  //               style={{ fontFamily: "Roboto" }}
  //             >
  //               {Number(price).toLocaleString("en-IN", {
  //                 style: "currency",
  //                 currency: "INR",
  //                 minimumFractionDigits: 0,
  //               })}
  //             </span>
  //             <span className="text-base text-neutral-500 font-normal">
  //               /night
  //             </span>
  //             <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"></span>
  //           </div>
  //         </span>
  //         {!!count && <StartRating reviewCount={count} point={avgRating} />}
  //       </div>

  //       <PopoverPopupState
  //         show={show}
  //         onClickShowPhoneNumber={() => onClickShowPhoneNumber()}
  //       />
  //     </div>
  //   );
  // };

  // Show Interest Popover
  interface PopoverPopupStateProps {
    show: boolean;
    onClickShowPhoneNumber: () => void;
  }

  // const PopoverPopupState: React.FC<PopoverPopupStateProps> = () => {
  //   return (
  //     <PopupState variant="popover" popupId="demo-popup-popover">
  //       {(popupState) => (
  //         <div>
  //           {/* <button
  //             className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 lg rounded-full px-6 py-3 w-full"
  //             {...bindTrigger(popupState)}
  //           > */}
  //           <button
  //             className={`${
  //               isSticky
  //                 ? "ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 lg rounded-full px-2.5 py-3 w-full"
  //                 : "ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 lg rounded-full px-6 py-3 w-full"
  //             }`}
  //             {...bindTrigger(popupState)}
  //           >
  //             Show Interest
  //           </button>
  //           <Popover
  //             className="mt-4"
  //             {...bindPopover(popupState)}
  //             anchorOrigin={{
  //               vertical: "bottom",
  //               horizontal: "center",
  //             }}
  //             transformOrigin={{
  //               vertical: "top",
  //               horizontal: "center",
  //             }}
  //           >
  //             {/* Popover content */}
  //             <div className="grid gap-4 p-3 md:grid-cols-2 xs:grid-cols-1 max-w-md bg-primary-6000 text-white">
  //               <div>
  //                 <p className="p-2 text-xs text-center break-words xs:break-words">
  //                   {`You can spend ${configData.coin} coins to view phone number`}
  //                 </p>

  //                 <ButtonPrimary
  //                   className="w-full bg-white text-primary-6000 hover:bg-white"
  //                   onClick={onClickShowPhoneNumber}
  //                 >
  //                   <span className="select-all">
  //                     {show
  //                       ? propertyData.ownerID.phoneNumber
  //                         ? propertyData.ownerID.phoneNumber
  //                         : propertyData.ownerID.email
  //                       : "Call immediately"}
  //                   </span>
  //                 </ButtonPrimary>
  //               </div>
  //               <div>
  //                 <p className="p-2 text-xs text-center xs:break-words">
  //                   {`You will get a callback from owner`}
  //                 </p>
  //                 <ButtonPrimary
  //                   className="w-full bg-white text-primary-6000 hover:bg-white"
  //                   onClick={sendEmail}
  //                 >
  //                   Get callback
  //                 </ButtonPrimary>
  //               </div>
  //             </div>
  //           </Popover>
  //         </div>
  //       )}
  //     </PopupState>
  //   );
  // };

  return (
    <div className="nc-ListingStayDetailPage lg:py-8 py-4">
      {/*  HEADER */}
      {showHeader ? (
        <NewHeader className="sticky  top-0 left-0 right-0 z-[999] 	" />
      ) : (
        ""
      )}
      {renderSearchBar()}
      {confirmDialogVisible && (
        <ConfirmationDialog
          open={confirmDialogVisible}
          onConfirm={handleConfirmPhoneNumber}
          onCancel={handleCancelPhoneNumber}
        />
      )}

      <>
        {loading ? (
          <GallerySkeleton />
        ) : (
          <>
            <header className="rounded-md sm:rounded-xl">
              <div
                className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2"
                style={
                  PHOTOS.length === 1 ? { height: "530px", width: "100%" } : {}
                }
              >
                <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                  onClick={(e) => {
                    handleOpenModalImageGallery(e);
                  }}
                >
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={PHOTOS[0]}
                    alt=""
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                </div>
                {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? "hidden sm:block" : ""
                    }`}
                  >
                    <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                      <img
                        className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                        src={item || ""}
                        alt=""
                        sizes="400px"
                      />
                    </div>

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={handleOpenModalImageGallery}
                    />
                  </div>
                ))}

                <button
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                  onClick={handleOpenModalImageGallery}
                >
                  <Squares2X2Icon className="w-5 h-5" />
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </button>
              </div>
            </header>
          </>
        )}
      </>

      {/* MAIN */}
      <main
        className={`${
          isSticky ? "z-10 mt-11 flex " : "z-10 mt-11 flex flex-col lg:flex-row"
        }`}
      >
        <div
          className={`${
            isSticky
              ? "xl:w-2/3 space-y-8 lg:space-y-10  lg:pr-10"
              : "lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10"
          }`}
        >
          {renderPropertyInfo()}
          {renderStayInfo()}
          {renderAmenities()}
          {renderPropertyRules()}
        </div>

        {/* SIDEBAR */}

        <>
          {isSticky ? (
            <>
              <div className="lg:block mt-5 flex-grow lg:mt-0 h-max">
                <div className="">{renderAvailability()}</div>
              </div>
            </>
          ) : (
            <>
              <div className="lg:block flex-grow mt-5 lg:mt-0">
                <div className="sticky top-28">{renderAvailability()}</div>
              </div>
            </>
          )}
        </>
      </main>
      <div className="nc-MainNav1 nc-MainNav2 relative z-10  lg:pt-20  xl:pt-28 xl:pb-20 py-10">
        {renderReviews()}
      </div>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
