import React, { FC, ReactNode } from "react";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import PropertyCardSkeleton from "./PropertyCardSkeleton";

export interface SectionGridFilterPropertiesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  info?: any;
  onClickTab?: any;
  loading?: boolean;
}

const SectionGridFilterProperties: FC<SectionGridFilterPropertiesProps> = ({
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Direcstays recommends for you",
  tabs = ["Star Properties", "Editors Pick"],
  info,
  onClickTab,
  loading,
}) => {

  return (

 

    <div className="nc-SectionGridFeaturePlaces relative mb-5 " >
      <HeaderFilter
        tabActive={"Star Properties"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={onClickTab}
      />
      
     
        {loading ? (
  <PropertyCardSkeleton />
        ): (
          <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
          {info.map((property: any) => (
            <StayCard
              key={property._id}
              currentProperty={property}
              data={property}
              size={"default"}
              className=""
            />
          ))}
        </div>
        )}
     
      
    </div>
  );
};

export default SectionGridFilterProperties;
