import React, { FC, useContext, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import AvatarDropdown from "./AvatarDropdown";
import { useLocation } from "react-router-dom";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AirbnbYourHome from "shared/Abnb/Abnb";
import { AuthContext } from "context/userContext";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { log } from "console";


export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  const [showSearchBarOnScroll, setShowSearchBarOnScroll] = useState(false);

  const hasToken = localStorage.getItem("token");
  
  const { pathname } = useLocation();
  const showSearchBarDetailPage = pathname.includes("/detail");
  const loginPage = pathname.includes("/login");
  const signupPage = pathname.includes("/signup");
  const verifyPage = pathname.includes("/verify")
  const authContext = useContext(AuthContext);

  // searchbar detail page
  const setShowSearchModal = authContext.setShowSearchModal;
  const {
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    endDate,
    handleDateChange,
    guests,
    setInfo,
    errorMsg,
    setErrorMsg,
  } = authContext;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 240) {
        setShowSearchBarOnScroll(true);
      } else {
        setShowSearchBarOnScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="lg:container lg:py-5 relative flex justify-between items-center  border-gray-200"> {/*removed border-b */}
        <Logo />
        {/* <div
          className="hidden lg:flex justify-center flex-1 items-center space-x-2 sm:space-x-6 lg:space-x-7 w-max  "                  
        >
          <div className="flex justify-center flex-[2] max-w-lg ">
            {showSearchBarDetailPage  ? (
              <div
                className="md:px-3"
                style={{
                  transition: "width 2s ease",
                  width: "155%",
                }}
              >
                <HeroSearchFormDetailPage />
              </div>
            ) : null}
          </div>
          </div> */}
        {showSearchBarDetailPage && showSearchBarDetailPage || loginPage ||  signupPage || verifyPage? (
          ""
        ) : (
          <div
            className={`hidden lg:flex justify-center flex-1 items-center space-x-2 sm:space-x-6 lg:space-x-7  `}
          >
            <div className="flex justify-center  w-full">
              <SectionHeroArchivePage
                searchLocationValue={searchLocationValue}
                setSearchLocationValue={setSearchLocationValue}
                startDate={startDate}
                endDate={endDate}
                handleDateChange={handleDateChange}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                currentPage="Stays"
                currentTab="Stays"
                className=""
              />
            </div>
          </div>
        )}

        <div className="flex  items-center cursor-pointer xs:max-w-fit xs:p-0">
          <a
            href="https://owners.ezstays.online/property"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4 className="lg:text-sm text-sm hover:bg-neutral-100 dark:bg-neutral-800 rounded-full py-2 px-4">
              EZstays Your Home
            </h4>
          </a>
          <div className="hidden items-center lg:flex space-x-1 ">
            {hasToken ? <AvatarDropdown /> : <AirbnbYourHome />}
          </div>
        </div>
{showSearchBarDetailPage && showSearchBarDetailPage ? ("") : (
  <div className="lg:hidden flex-[3] max-w-lg lg:!mx-auto md:px-3">
  <HeroSearchForm2MobileFactory />
</div>
)}
      

        {/* <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100 ">
          
        </div> */}
      </div>
    </div>
  );
};

export default MainNav2;
function functFavourite() {
  throw new Error("Function not implemented.");
}

function setInfo(propertydata: any) {
  throw new Error("Function not implemented.");
}
