import React, { FC, useContext } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";

import { AuthContext } from "context/userContext";
import axios from "axios";
import { API_URL } from "api/config";

export interface StayCardProps {
  key: String;
  currentProperty: any;
  data: any;
  size: String;
  className: String;
}

const StayCard: FC<StayCardProps> = ({ data, size, className }) => {
  const {
    _id,
    type,
    title,
    country,
    floor,
    area_name,
    landmark,
    price,
    city,
    beds,
    cover_image,
    galleryImgs,
    avgRating,
    count,
  } = data;

  const authContext = useContext(AuthContext);
  const dataFavourite = authContext.favPropData;
  const addtoSavedList = authContext.addtoSavedList;
  const delFromSavedList = authContext.delFromSavedList;

  const address = `${floor ? `${floor},` : ""}  ${
    area_name ? `${area_name},` : ""
  } ${landmark ? `${landmark},` : ""} ${city ? `${city},` : ""} ${
    country ? `${country}` : ""
  } ${" "}`;

  const propertyViewsCount = async () => {
    try {
      const response = await axios.post(`${API_URL}/property/views-count`, {
        propId: _id,
      });
      if (response.data.error === false) {
        return response.data.viewsCount;
      } else {
        console.log("Error updating views count", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSliderGallery = () => {
    const combinedArray = [cover_image || "", ...(galleryImgs || [])];
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={combinedArray}
          href={`/detail?propID=${_id}`}
          propertyId={_id}
          propertyViewsCount={propertyViewsCount}

        />
        <BtnLikeIcon
          addtoSavedList={addtoSavedList}
          idd={_id}
          dataFavourite={dataFavourite}
          delFromSavedList={delFromSavedList}
          className="absolute right-3 top-3 z-[1]"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="lg:text-sm text-xs text-neutral-500 dark:text-neutral-400">
            {type} . {beds} beds
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "lg:text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="line-clamp-1">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span
            className="text-base font-semibold"
            style={{ fontFamily: "Roboto" }}
          >
            {Number(price).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span>
          {!!count && <StartRating reviewCount={count} point={avgRating} />}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/detail?propID=${_id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
